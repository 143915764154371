
import {computed, defineComponent, getCurrentInstance, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Field} from "vee-validate";
import MasterConfig from "@/core/config/MasterConfig"
import {commonf} from "@/core/filters/common";

export default defineComponent({
  name: "image-gallery",
  components: {
    Field,
  },
  props: {
    catID: Number,
    hotelID: String,
    factSheetID: String,
    market: String,
    lang: String,
    fsType: String,
    fsTypeID: String,
  },
  data: function () {
    return {
      myDefaultImage: MasterConfig.general.defaultImage,
      searchName1: '',
      myImage: [MasterConfig.general.defaultImage],
      myImageFile: [MasterConfig.general.defaultImage],
      fileCount: 1,
      searchName: '',
      myUpdateStatus: [],

    }
  },
  computed: {
    filteredList() {
      if (this.myList1.data != null) {
        return this.myList1.data.filter(item =>
            !(item.Title).toLowerCase().indexOf(this.searchName.toLowerCase())
        );
      } else {
        return true;
      }
    },
    myList1() {
      return store.getters.filesList;
    }
  },
  methods: {
    imagePath(key) {
      return MasterConfig.general.imagePath + this.filteredList[key].ThumbPath;
    },
    myMeth(e) {
      //console.log(e.target.files.length);
      this.fileCount = e.target.files.length;
      for (let i = 0; i < this.fileCount; i++) {
        const file = e.target.files[i];
        this.myImage[i] = URL.createObjectURL(file);
        this.myImageFile[i] = e.target.files[i].name;
      }
      if (this.fileCount < 1) this.fileCount = 1;let el = (document.getElementById('imageFile') as HTMLInputElement);
    },
    clearForm() {
      let el = (document.getElementById('imageFile') as HTMLInputElement);
      el.value = null;
      this.myImage = [];
      this.myImageFile = []
      this.myImageDescription = [];
      this.myImageTitle = [];
      this.myImage = [MasterConfig.general.defaultImage];
      this.myImageFile = [MasterConfig.general.defaultImage];
      this.fileCount = 1;
      console.log(this.myImageTitle)
    },
    removeImage(id) {
      this.myImage[id] = MasterConfig.general.defaultImage;
    },
    showImage(key){
      return MasterConfig.general.imagePath+this.filteredList[key].Path;
    },
    imageSize(byte) {
      let mySize = commonf.byteToMb(byte)
      return mySize.value +" "+mySize.unit;
    },
    setUpdate(id) {
      this.myUpdateStatus[id] = true;
    },
    orderImage(key,Action){
      if(this.filteredList[key].ChildID!==null && this.filteredList[key].SubChildID!==null){
        const payload = {
          ID: this.filteredList[key].ID,
          Action: Action,
          Type: this.filteredList[key].Type,
          ParentID: this.filteredList[key].ParentID,
          ChildID: this.filteredList[key].ChildID,
          SubChildID: this.filteredList[key].SubChildID,
        }
        store.dispatch(Actions.FILES_ORDER, payload)
      }else {
        const payload2 = {
          ID: this.filteredList[key].ID,
          Action: Action,
          Type: this.filteredList[key].Type,
          ParentID: this.filteredList[key].ParentID,
        }
        store.dispatch(Actions.FILES_ORDER, payload2)
      }

    },
    deleteImage(key) {
      const payload = {
        ID: this.filteredList[key].ID,
        Type: this.filteredList[key].Type,
        ParentID: this.filteredList[key].ParentID,
        ChildID: this.filteredList[key].ChildID,
        SubChildID: this.filteredList[key].SubChildID,
      }

      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {

        if (result.isConfirmed) {
          console.log(payload);
          store.dispatch(Actions.FILES_DELETE, payload)
              .then(() => {
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getFilesErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    },
    updateImage(key) {
      const payload2 = {
        ID: this.filteredList[key].ID,
        Title: this.filteredList[key].Title,
        Description: this.filteredList[key].Description,
        is_active: this.filteredList[key].is_active,
      };
      console.log(payload2);
      store.dispatch(Actions.FILES_EDIT, payload2)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde Güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
            this.myUpdateStatus[this.filteredList[key].ID] = false;
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getFilesErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!-01",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    },

  },
  setup() {

    const myList2 = computed(() => {
      return store.getters.selectedFiles;
    });
    const mySelectedList2 = computed(() => {
      return store.getters.filesList;
    });

    let myImageTitle = ref([]);
    let myImageDescription =  ref([]);
    const insertItem = (ID) => {

      if (myImageTitle.value[ID] == null) {
        Swal.fire({
          text: "Lütfen 'Başlık' bilgisini doldurunuz.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return;
      }
      let myFiles = document.getElementById("imageFile") as HTMLInputElement;
      let newFileName = myFiles.files[ID].name.replace(/[^0-9a-zA-Z.]/g, "");
      const payload2 = {
        Title: myImageTitle.value[ID],
        Description: myImageDescription.value[ID],
        Type: myList2.value.Type,
        ParentID: myList2.value.ParentID,
        ChildID: myList2.value.ChildID,
        SubChildID: myList2.value.SubChildID,
        FileName: newFileName,
      }
      let idBtn = "btnImg" + ID;
      let button = document.getElementById(idBtn) as HTMLElement;
      button.setAttribute('data-kt-indicator', 'on');
      let newClass = button.getAttribute('class').replace("btn-primary", "btn-warning");
      button.setAttribute('class', newClass);


      let formData = new FormData();
      formData.append("file", myFiles.files[ID], newFileName);
      fetch(MasterConfig.general.uploadPath, {
        method: "POST",
        body: formData,
      }).then(() => {
        store.dispatch(Actions.FILES_INSERT, payload2)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
              newClass = newClass.replace("btn-warning", "btn-success");
              button.setAttribute('class', newClass);
              button.setAttribute('disabled', "true");
              button.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              button.removeAttribute("data-kt-indicator");
              newClass = newClass.replace("btn-warning", "btn-primary");
              button.setAttribute('class', newClass);

              Swal.fire({
                text: store.getters.getFilesErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!-01",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
      }).catch(() => {
        button.removeAttribute("data-kt-indicator");
        newClass = newClass.replace("btn-warning", "btn-primary");
        button.setAttribute('class', newClass);
        Swal.fire({
          text: store.getters.getFilesErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!-02",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    }


    return {
      myList2,
      mySelectedList2,
      insertItem,
      myImageTitle,
      myImageDescription,
    };
  },
});
